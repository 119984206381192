import React from 'react';

function TableChart(props) {
    return (
        <table width={980} height={600} style={{ backgroundColor: 'white' }}>
            <thead>
            <tr>
                <th>Rank</th>
                <th>Rank Change</th>
                <th>Domain</th>
                <th>Category</th>
            </tr>
            </thead>
            <tbody>
            {props["data"].map((item) => (
                <tr key={item.id} style={{ backgroundColor: item.id % 2 === 0 ? '#eee' : 'white' }}>
                    <td>{item.rank}</td>
                    <td>{item.rankChange}</td>
                    <td>{item.domain}</td>
                    <td>{item.category}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default TableChart;