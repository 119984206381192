import React, { useState } from 'react';

function Button(props) {
    const [isHovered, setIsHovered] = useState(false);

    return (
        <button
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
            style={{
                backgroundColor: '#f5f5f5',
                border: '2px solid #004a57',
                borderRadius: '5px',
                color: '#004a57',
                width: '300px',
                height: '75px',
                cursor: isHovered ? 'pointer' : 'default',
                padding: '10px 20px',
                fontSize: '15px',
                transform: isHovered ? 'scale(1.1)' : 'scale(1)'
            }}
        >
            {props.buttonText}
        </button>
    );
}

export default Button;