import React from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend
} from 'recharts';

function LChart (props) {
    return (
        <LineChart width={1000} height={600} data={props.data}>
            <XAxis dataKey="Time" tick={false} />
            <YAxis dataKey={props.dataKey} />
            <CartesianGrid stroke="#ccc" />
            <Tooltip />
            <Legend />
            <Line type="monotone" dataKey={props.dataKey} stroke="#8884d8" />
        </LineChart>
    );
}

export default LChart;