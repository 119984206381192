import React from "react"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faArrowAltCircleLeft} from "@fortawesome/free-solid-svg-icons";

const ChevronBackButton = () => (
    <div style={{ fontSize: '48px' }} className="back">
        <FontAwesomeIcon icon={faArrowAltCircleLeft} color="#fff" />
    </div>
);

export default ChevronBackButton;