import './App.css';
import Button from "./button";
import $ from 'jquery';
import React, {useState} from 'react';
import LChart from "./linechart";
import ChevronBackButton from "./back"
import TableChart from "./table";

function App() {
    /**
     * tChart: Stores chart data of traffic change
     * pChart: Stores chart data of popular domains
     * aChart: Stores chart data of attack layers
     */

    let [tChartData, setDataT] = useState([]);
    let [pChartData, setDataP] = useState([]);
    let [aChartData, setDataA] = useState([]);

    /**
     * Response from UI button press for traffic change
     */
    function trafficClick() {
        if (tChartData.length > 0) {
            pagePrep();
            $(".tChart").css("display", "flex");
            return;
        }
        $.ajax({
            url: 'https://generalcloudflare.assafica.workers.dev/traffic-change',
            type: 'GET',
            dataType: 'json',
            success: function(response) {
                let data = response[0]["http"];
                let arr = [];
                for (let i = 0; i < data["timestamps"].length; i++) {
                    arr.push({
                        "Time": data["timestamps"][i],
                        "Current Traffic Change": data["values"][i]
                    })
                }
                setDataT(arr);
                $(".tChart").css("display", "flex");
                pagePrep();
            }
        });
    }

    /**
     * Response from UI button press for popular domains
     */
    function domainClick() {
        if (pChartData.length > 0) {
            pagePrep();
            $(".pChart").css("display", "flex");
            return;
        }
        $.ajax({
            url: 'https://generalcloudflare.assafica.workers.dev/popular-domains',
            type: 'GET',
            dataType: 'json',
            success: function(response) {
                setDataP(response["rankingEntries"]);
                $(".pChart").css("display", "flex");
                pagePrep();
            }
        });
    }

    /**
     * Response from UI button press for attack click
     */
    function attackClick() {
        if (aChartData.length > 0) {
            pagePrep();
            $(".aChart").css("display", "flex");
            return;
        }
        $.ajax({
            url: 'https://generalcloudflare.assafica.workers.dev/attack-layer3',
            type: 'GET',
            dataType: 'json',
            success: function(response) {
                let data = response[0]["total"];
                let arr = [];
                for (let i = 0; i < data["timestamps"].length; i++) {
                    arr.push({
                        "Time": data["timestamps"][i],
                        "Layer 3 DDoS Attack Percentage": data["values"][i] * 100
                    })
                }
                setDataA(arr);
                $(".aChart").css("display", "flex");
                pagePrep();
            }
        });
    }

    /**
     * Preps the chart pages by swapping UI element displays
     */
    function pagePrep() {
        $(".button").hide();
        $(".back").show();
    }

    /**
     * Deals with UI element display changes on back button press
     */
    function backButtonClick() {
        $(".tChart, .pChart, .aChart, .back").hide();
        $(".button").show();
    }

    return (
    <div className="App">
        <div className="button TrafficButton" onClick={trafficClick}>
            <Button buttonText="Current Traffic Change" />
        </div>
        <div className="button DomainButton" onClick={domainClick}>
            <Button buttonText="List of Popular Domains" />
        </div>
        <div className="button AttackButton" onClick={attackClick}>
            <Button buttonText="Layer 3 DDoS Attacks"/>
        </div>
        <div className="tChart">
            <LChart data={tChartData} dataKey="Current Traffic Change"/>
        </div>
        <div className="pChart">
            <TableChart data={pChartData}/>
        </div>
        <div className="aChart">
            <LChart data={aChartData} dataKey="Layer 3 DDoS Attack Percentage"/>
        </div>
        <div onClick={backButtonClick}>
            <ChevronBackButton></ChevronBackButton>
        </div>
    </div>
    );
}

export default App;
